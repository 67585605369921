import React from "react";

const GridSection = (props) => {
  const { containerWidthClass, containerGridClass, containerGridGap } = props;
  return (
    <div className={`container ${containerWidthClass} my-[72px]`}>
      <div className={`grid ${containerGridClass} ${containerGridGap}`}>{props?.children}</div>
    </div>
  );
};

export default GridSection;
